import React from "react";

import { PublicLayout } from "../components/layouts/PublicLayout";
import { Login } from "../containers/login/Login";

const LoginPage = () => {
    return (
      <PublicLayout>
        <Login adminPage={false} />
      </PublicLayout>
    ) ;
}

export default LoginPage ;